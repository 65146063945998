<script>
import AbstractLayout from './AbstractLayout.vue';

export default {
  extends: AbstractLayout,
  name: 'Layout',
  data () {
    return {
      modalClosedHandle: null,
    }
  },
  watch: {
    '$route.params.entrySlug': function () {
      // do nothing
    },
  },
  created() {
    this.modalClosedHandle = this.handleModalClose.bind(this);

    this.$root.$on('modalclosed', this.modalClosedHandle);

  },
  methods: {
    handleModalClose() {
      this.$store.dispatch('updateSeoMeta', {
        title: this.seoTitle,
        description: this.seoDescription,
        image: this.seoImage,
        noIndexNoFollow: this.seoNoIndexNoFollow,
      });
    },
  },
  beforeDestroy() {
    this.$root.$off('modalclosed', this.modalClosedHandle);
  },
  computed: {
    mySlugToWatch: function () {
      if (this.$route.meta.hasModal === true) {
        return this.loadedUri;
      } else {
        return this.uri;
      }
    },
    uri: function () {
      if(!this.$route.params.pageSlug) {
        return 'corporate';
      } else if(this.$route.params.pageSlug && this.$route.params.entrySlug && !this.$route.meta.hasModal) {
        return `corporate/${this.$route.params.pageSlug}/${this.$route.params.entrySlug}`;
      } else {
        return `corporate/${this.$route.params.pageSlug}`;
      }
    },
  },
  beforeRouteUpdate (_to, _from, next) {
    document.scrollTop = 0;
    next();
  },
}
</script>
