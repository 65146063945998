// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../tmp/.npm/_npx/172/lib/node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../tmp/.npm/_npx/172/lib/node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../tmp/.npm/_npx/172/lib/node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/icons/link.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/icons/facebook.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/icons/pinterest.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".share-button[data-v-17a251e8]{display:block;width:48px;height:48px;border-radius:50%;background-color:var(--modalContextColor);position:relative;transition:all .3s ease;cursor:pointer}@media(min-width:768px){.share-button[data-v-17a251e8]{width:56px;height:56px}}.share-button[data-v-17a251e8]:after{content:\"\";position:absolute;display:block;width:20px;height:20px;top:14px;left:14px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-position:50%;background-repeat:no-repeat}@media(min-width:768px){.share-button[data-v-17a251e8]:after{top:18px;left:18px}}.share-button[data-v-17a251e8]:hover{background-color:var(--modalContextColorDarker)}.share-button.is-facebook[data-v-17a251e8]:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.share-button.is-pinterest[data-v-17a251e8]:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.share-button.has-tooltip[data-v-17a251e8]:before{content:\"copy to clipboard\";position:absolute;bottom:calc(100% + 6px);left:50%;transform:translateX(-50%);background-color:#000;color:#fff;opacity:0;padding:6px 14px;border-radius:15px;white-space:nowrap;transition:all .3s ease}.share-button.has-tooltip[data-v-17a251e8]:hover:before{opacity:1;bottom:calc(100% + 8px)}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
