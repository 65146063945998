import gql from "graphql-tag";

// common
import NextEntryFragment from '../components/fragments/common/NextEntry';

// page blocks
import GridBlockFragment from '../components/fragments/pageBlocks/GridBlock';
import CollectionsTeaserFragment from '../components/fragments/pageBlocks/CollectionsTeaser';

// entry blocks
import EntryBlockMediaFragment from '../components/fragments/entryBlocks/Media';
import EntryBlockMediaGridFragment from '../components/fragments/entryBlocks/MediaGrid';
import EntryBlockTwoMediaFragment from '../components/fragments/entryBlocks/TwoMedia';
import EntryBlockQuoteFragment from '../components/fragments/entryBlocks/Quote';
import EntryBlockImageSliderFragment from '../components/fragments/entryBlocks/ImageSlider';
import EntryBlockFormattedListFragment from '../components/fragments/entryBlocks/FormattedList';
import EntryBlockCopyFragment from '../components/fragments/entryBlocks/Copy';
import EntryBlockCheckListFragment from '../components/fragments/entryBlocks/CheckList';
import EntryHeadlineCopyFragment from '../components/fragments/entryBlocks/HeadlineCopy';
import EntryButtonFragment from '../components/fragments/entryBlocks/Button';
import EntryAwards from '../components/fragments/entryBlocks/Awards';
import EntryAwardsWithCopy from '../components/fragments/entryBlocks/AwardsWithCopy';
import EntryTimeline from '../components/fragments/entryBlocks/Timeline';
import EntryAwardsWithHeadlineAndCopy from '../components/fragments/entryBlocks/AwardsWithHeadlineAndCopy';

const PageBlockFragments = `
pageBlocks {
  blockType: __typename
  ${GridBlockFragment}
  ${CollectionsTeaserFragment}
}
`;

const EntryBlockFragments = `
entryBlocks {
  blockType: __typename
  ${EntryBlockMediaFragment}
  ${EntryBlockMediaGridFragment}
  ${EntryBlockTwoMediaFragment}
  ${EntryBlockQuoteFragment}
  ${EntryBlockImageSliderFragment}
  ${EntryBlockFormattedListFragment}
  ${EntryBlockCopyFragment}
  ${EntryBlockCheckListFragment}
  ${EntryHeadlineCopyFragment}
  ${EntryButtonFragment}
  ${EntryAwards}
  ${EntryAwardsWithCopy}
  ${EntryTimeline}
  ${EntryAwardsWithHeadlineAndCopy}
}
`;

const globalSetFragment = `
  globalSet(site: $site) {
    ... on globals_GlobalSet {
      readMoreDefaultCopy
      randomLetters {
        ... on randomLetters_letter_BlockType {
          uid
          letter
          like
          copy
        }
      }
      collectionHeadlineWithLinks {
        ... on collectionHeadlineWithLinks_copy_BlockType {
          uid
          basePlainText
        }
        ... on collectionHeadlineWithLinks_linkEntry_BlockType {
          uid
          baseEntryLink {
            uri
            title
          }
          baseCtaCopy
          baseColorpicker
        }
      }
      collectionHeadlineColors {
        ... on collectionHeadlineColors_color_BlockType {
          colorName
          color
          entrylink {
            uri
          }
        }
      }
      globalSectionHandleName {
        corporateCollection: col1
        corporateProducts: col2
        corporateCompany: col3
        corporateSales: col4
        corporateSustainability: col5
      }
      collectionHeadlineWithColoredLinks
      metaTitle
      metaDescription
      metaOgImage {
        url
      }
    }
  }
`;

const SeoFragment = `
  metaTitle
  metaDescription
  metaOgImage {
    url
  }
  metaNoIndexNoFollow
`;

export const PAGE_DATA_QUERY = gql`
query PageData($uri: [String], $site: [String]) {
  ${globalSetFragment}
  entry(uri: $uri, site: $site) {
    title
    slug
    uri
    id
    sectionHandle
    ... on pages_pages_Entry {
      ${PageBlockFragments}
      ${EntryBlockFragments}
      ${SeoFragment}
    }
    ... on corporateCollection_corporateCollection_Entry {
      ${EntryBlockFragments}
      ${PageBlockFragments}
      ${SeoFragment}
    }
    ... on corporateCompany_corporateCompany_Entry {
      ${NextEntryFragment}
      ${EntryBlockFragments}
      ${PageBlockFragments}
      ${SeoFragment}
    }
    ... on corporateProducts_corporateProducts_Entry {
      ${NextEntryFragment}
      ${EntryBlockFragments}
      ${PageBlockFragments}
      ${SeoFragment}
    }
    ... on corporateSales_corporateSales_Entry {
      ${NextEntryFragment}
      ${EntryBlockFragments}
      ${PageBlockFragments}
      ${SeoFragment}
    }
    ... on corporateSustainability_corporateSustainability_Entry {
      ${NextEntryFragment}
      ${EntryBlockFragments}
      ${PageBlockFragments}
      ${SeoFragment}
    }
  }
}
`;

