export default `
... on entryBlocks_imageSlider_BlockType {
    medias: children {
    ... on entryBlocks_mediaWithCaption_BlockType {
      baseImage {
        url
        kind
        width
        height
        hasFocalPoint
        focalPoint
      }
      baseVideo {
        url
        kind
      }
      baseCaption
    }
  }
}
`;
