import { render, staticRenderFns } from "./BrowserSupportPage.vue?vue&type=template&id=65cc8ebf&scoped=true"
import script from "./BrowserSupportPage.vue?vue&type=script&lang=js"
export * from "./BrowserSupportPage.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./BrowserSupportPage.vue?vue&type=style&index=0&id=65cc8ebf&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../tmp/.npm/_npx/172/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "65cc8ebf",
  null
  ,true
)

export default component.exports