export default `
... on entryBlocks_mediaGrid_BlockType {
  baseCaption
  baseInverted
  children {
    ... on entryBlocks_mediaWithoutCaption_BlockType {
      baseImage {
        url
        kind
        hasFocalPoint
        focalPoint
      }
      baseVideo {
        url
        kind
      }
    }
  }
}
`;
