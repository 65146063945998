<template>
  <div id="app">
    <div v-if="!showError && browserSupported">
      <div hidden id="sprite-plyr">
        <svgicon icon="plyr" :original="true"/>
      </div>
      <router-view ref="mainLayout"/>
      <Modal v-if="$route.meta.hasModal === true" ref="modal"><router-view name="modal"/></Modal>
    </div>
    <ErrorPage v-if="showError && browserSupported" />
    <BrowserSupportPage v-if="!browserSupported" />
</div>
</template>

<script>
import Vue from 'vue';
import router from './router';
import store from './store';
import Modal from './views/Modal';
import VueImgix from 'vue-imgix';
import plyrSprites from 'plyr/dist/plyr.svg';
import VueSvgIcon from 'vue-svgicon';
import VueMeta from 'vue-meta';
import ErrorPage from './views/ErrorPage';
import BrowserSupportPage from './views/BrowserSupportPage';
import Vue2TouchEvents from 'vue2-touch-events';

const supportedBrowsers = {
  Chrome: "57",
  Firefox: "52",
  "Microsoft Edge": "12",
  Safari: "11",
  // Mobile
  "Samsung Internet": "7",
  "Chrome Mobile": "57",
  "Firefox Mobile": "52",
};

function checkBrowserSupport () {
  if(!window.platform) {
    return true;
  }
  let browser = window.platform.name;
  let version = window.platform.version;
  return parseInt(version) >= parseInt(supportedBrowsers[browser]);
}

const supported = checkBrowserSupport();

Vue.use(VueMeta);
Vue.use(VueSvgIcon);
Vue.use(Vue2TouchEvents);
Vue.mixin({router, store})

Vue.use(VueImgix, {
  domain: 'i.ringana.com',
  defaultIxParams: {
    auto: 'format'
  }
});

export default {
  name: 'App',
  components: {
    Modal,
    ErrorPage,
    BrowserSupportPage,
  },
  data () {
    return {
      browserSupported: supported,
    }
  },
  metaInfo() {
    let metaTags = [
      {
          name: 'description',
          content: this.$store.state.seo.description,
      },
      {
          property: 'og:title',
          content: this.$store.state.seo.title,
      },
      {
          property: 'og:description',
          content: this.$store.state.seo.description,
      },
      {
          property: 'og:image',
          content: this.$store.state.seo.image,
      },
    ];

    const noIndexNoFollow = this.$store.state.seo.noIndexNoFollow;

    if(noIndexNoFollow.length) {
      const content = noIndexNoFollow.join(' ');
      metaTags.push({
        name: 'robots',
        content,
      });
    }

    return {
      title: this.$store.state.seo.title,
      meta: metaTags,
      titleTemplate: '%s - RINGANA',
    };
  },
  computed: {
    returnUri () {
      return `/${this.$refs.mainLayout.loadedUri}`;
    },
    plyrSprites () {
      return plyrSprites;
    },
    showError () {
      return this.$store.state.error;
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss">

@import '~destyle.css';
@import '~swiper/swiper-bundle.css';
@import '~plyr/dist/plyr.css';
@import './assets/styles/tw.css';
@import './assets/styles/typo.scss';
@import './assets/styles/variables.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --modalContextColor: $black;
  font-family: Eina01-Regular;
  margin-bottom: 8px;

}

*, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: normal;
    word-break: normal;
    word-wrap: normal;
}

</style>
