<template>
	<div class="tag-button" :style="{ '--tag-background-color': ColorItHelper.restructureColorcode(color), '--tag-hover-color': ColorItHelper.restructureColorcode(hoverColor) }" :class="{ 'is-inverted': isInverted }">
		<Title><slot /></Title>
	</div>
</template>
<script>
import Title from './typo/Title';
import ldc from '../utils/lightenDarkenColor';
import ColorItHelper from '../helpers/colorit.helper';

export default {
  data() {
    return {
      ColorItHelper,
    };
  },
	components: {
		Title,
	},
	props: {
		color: {
			type: String,
			required: false,
		},
		inverted: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		isInverted() {
			return this.color === '#000000' || this.inverted;
		},
		hoverColor() {
			if(this.color === '#000000') {
				return ldc(this.color, 80);
			}
			return ldc(this.color, -30);
		},
	},
}
</script>
<style lang="scss" scoped>

@import '../assets/styles/variables.scss';

.tag-button {
	--tag-background-color: #000000;
	padding: 8px 16px;
	border-radius: 26px;
	background-color: var(--tag-background-color);
	display: inline-block;
	color: $black;
	position: relative;
	transition: all 0.3s ease;

	@media (min-width: $br-md) {
		padding: 10px 24px;
	}

	&.is-inverted {
		color: $white;
	}

	::v-deep {
		.typo-title {
			position: relative;
			top: -2px;
		}
	}

	&:hover {
		background-color: var(--tag-hover-color);
	}
}
</style>
