<script>
import Caption from './typo/Caption';

export default {
	components: {
		Caption,
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
		arrow: {
			type: Boolean,
			required: false,
		},
		icon: {
			type: String,
			required: false,
		},
		iconPosition: {
			type: String,
			required: false,
		},
		arrowLeft: {
			type: Boolean,
			required: false,
		},
	},
	computed: {
		type() {
			return this.data.type;
		},
		to() {
			if(this.data.type === 'entry') {
				return this.data.element ? `/${this.data.element.uri}` : `/${this.data.url}`
			} else {
				return null;
			}
		},
		href() {
			if(this.data.type === 'entry') {
				return this.to;
			} else {
				return this.data.url;
			}
		},
		text() {
			if(this.data.customText) {
				return this.data.customText;
			} else {
				return '';
			}
		},
		iconType() {
			switch(this.icon) {
				case 'download': return 'has-download';
				case 'link': return 'has-arrow';
				default: return '';
			}
		},
		iconPos() {
			return this.iconPosition === 'left' ? 'icon-is-left' : null;
		},
		aria() {
			return this.data.ariaLabel;
		},
		target() {
			return this.data.target;
		}
	},
    render(h) {
        return h(
			this.type === 'entry' ? 'router-link' : 'a',
			{
				class: [
                  'button',
                  this.iconType,
                  this.iconPos,
                  this.arrowLeft ? 'arrow-is-left' : null,
				],
				attrs: {
					href: this.href,
					download: this.type === 'asset',
					'aria-label': this.aria,
					target: this.target,
					rel: this.target ? 'noopener noreferrer' : null,
				},
				props: {
					to: this.to,
				},
			},
			[
				h(Caption,
					{},
					this.text ? this.text : this.$slots.default
				)
			]
        );
    }
}
</script>
<style lang="scss" scoped>
@import '../assets/styles/variables.scss';

.button {
	padding: 14px 24px;
	color: $white;
	background-color: var(--modalContextColor);
	border-radius: 24px;
	display: inline-flex;
	align-items: center;
	transition: all 0.3s ease;

	&:after {
		content: '';
		display: none;
		width: 20px;
		height: 20px;
		position: relative;
		margin-left: 10px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		left: 0;
		transition: all 0.3s ease;
	}

	::v-deep {
		.typo-caption {
			font-size: 14px;
		}
	}

	&:hover {
		background-color: var(--modalContextColorDarker);
	}

	&.is-inverted {
		color: $black;
	}

    &.icon-is-left {
		flex-direction: row-reverse;
		&:after {
			margin-left: 0;
			margin-right: 10px;
		}
	}

	&.has-arrow {
		&:after {
			display: inline-block;
			background-image: url('../assets/icons/arrow-right-white.svg');
		}

		&.is-inverted {
			background-image: url('../assets/icons/arrow-right.svg');
		}

		&:hover {
			&:after {
				left: 4px;
			}
		}


		&.arrow-is-left {
			&:after {
				transform: rotate(180deg);
			}

			&:hover {
				&:after {
					left: -4px;
				}
			}
		}
	}

	&.has-download {
		&:after {
			display: inline-block;
			background-image: url('../assets/icons/download-white.svg');
		}

		&.is-inverted {
			background-image: url('../assets/icons/download.svg');
		}
	}
}
</style>
