// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../tmp/.npm/_npx/172/lib/node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../tmp/.npm/_npx/172/lib/node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../tmp/.npm/_npx/172/lib/node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/icons/arrow-right-white.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/icons/arrow-right.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/icons/download-white.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../assets/icons/download.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button[data-v-c7379c12]{padding:14px 24px;color:#fff;background-color:var(--modalContextColor);border-radius:24px;display:inline-flex;align-items:center}.button[data-v-c7379c12],.button[data-v-c7379c12]:after{transition:all .3s ease}.button[data-v-c7379c12]:after{content:\"\";display:none;width:20px;height:20px;position:relative;margin-left:10px;background-position:50%;background-repeat:no-repeat;background-size:contain;left:0}.button[data-v-c7379c12] .typo-caption{font-size:14px}.button[data-v-c7379c12]:hover{background-color:var(--modalContextColorDarker)}.button.is-inverted[data-v-c7379c12]{color:#000}.button.icon-is-left[data-v-c7379c12]{flex-direction:row-reverse}.button.icon-is-left[data-v-c7379c12]:after{margin-left:0;margin-right:10px}.button.has-arrow[data-v-c7379c12]:after{display:inline-block;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.button.has-arrow.is-inverted[data-v-c7379c12]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.button.has-arrow[data-v-c7379c12]:hover:after{left:4px}.button.has-arrow.arrow-is-left[data-v-c7379c12]:after{transform:rotate(180deg)}.button.has-arrow.arrow-is-left[data-v-c7379c12]:hover:after{left:-4px}.button.has-download[data-v-c7379c12]:after{display:inline-block;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.button.has-download.is-inverted[data-v-c7379c12]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
