<template>
    <div class="typo-caption" :class="{'is-strong': strong}"><slot /></div>
</template>
<script>
export default {
    props: {
        strong: {
            type: Boolean,
            required: false,
        }
    }
}
</script>
<style lang="scss" scoped>

@import '../../assets/styles/variables.scss';

    .typo-caption {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 140%;

        &.is-strong {
            font-weight: 600;
        }
    }
</style>
