export default `
... on pageBlocks_gridItemLink_BlockType {
  gridItemType: __typename
  uid
  baseColorpicker
  baseBlockSize: baseBlockSizeSimple
  baseDisplayNew
  baseCtaCopy
  baseUrl
  baseFontLight
  basePlainText
  baseImage {
    url
    kind
    hasFocalPoint
    focalPoint
  }
  baseImageGradient
  baseVideo {
    url
    kind
    mimeType
  }
}
`;
