import Vue from 'vue';
import Vuex from 'vuex';

import { fetchURL } from '../cms/api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    collectionHandles: [],
    collectionHandlesLoaded: false,
    seo: {
      title: 'RINGANA - RINGANA',
      description: 'Ringana',
      image: 'imageurl',
      noIndexNoFollow: [],
    },
    error: false,
  },
  mutations: {
    setCollectionHandles(state, uris) {
      const handles = uris.map(uri => {
        return uri.split('/')[1];
      });
      state.collectionHandles = handles;
    },
    setCollectionHandlesLoaded(state) {
      state.collectionHandlesLoaded = true;
    },
    setSeoMeta(state, payload) {
      state.seo.title = payload.title;
      state.seo.description = payload.description;
      state.seo.image = payload.image;
      state.seo.noIndexNoFollow = payload.noIndexNoFollow;
    },
    setErrorState(state, payload) {
      state.error = payload;
    },
  },
  actions: {
    async loadCollectionHandles({ commit }) {
      const response = await fetchURL(`https://corporate.api.ringana.com/collection-uris.json`);
      if(response.uris) {
        commit('setCollectionHandles', response.uris);
        commit('setCollectionHandlesLoaded');
      } else {
        throw new Error('could not load collection handels');
      }
    },
    updateSeoMeta({ commit }, payload) {
      commit('setSeoMeta', payload);
    },
    updateErrorState({ commit }, payload) {
      commit('setErrorState', payload);
    },
  },
  modules: {
  }
});
