<script>
import AbstractLayout from './AbstractLayout.vue';

export default {
  extends: AbstractLayout,
  name: 'ModalLayout',
  computed: {
    mySlugToWatch: function () {
      return `${this.$route.params.pageSlug}::${this.$route.params.entrySlug}`
    },
    uri: function () {
      return `corporate/${this.$route.params.pageSlug}/${this.$route.params.entrySlug}`;
    },
  },
  beforeRouteUpdate (_to, _from, next) {
    this.$parent.$refs.modalwrapper.scrollTop = 0;
    next();
  },
}
</script>
