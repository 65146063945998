<template>
	<div class="next-entry" :style="{'--modalContextColor': modalContextColor, '--modalContextColorDarker': modalContextColorDarker}">
		<router-link :to="nextEntryHref" class="next-entry-image" :style="{backgroundImage: 'url(' + image + ')'}"></router-link>
		<div class="next-entry-copy">
			<Title category>{{ subline }}</Title>
			<Heading :tag-level="4" :style-level="2">{{ headline }}</Heading>
			<Button icon="link" :data="{ url: href, type: buttonType }">{{ cta }}</Button>
		</div>
	</div>
</template>
<script>
import Title from './typo/Title';
import Heading from './typo/Heading';
import Button from './Button';
import colors from '../entryBackgroundColor';

export default {
	components: {
		Title,
		Heading,
        Button,
	},
	data() {
		return {
            data: {},
            headline: '',
            subline: '',
            cta: '',
            href: '',
            buttonType: 'entry',
            image: '',
			sectionHandle: '',
			dataloadedHandle: null,
		};
	},
    methods: {
        async getRandomEntry(id, globalSet, lang) {
			let fetchURL = `https://corporate.api.ringana.com/random-entry.json?excludeId=${id}`;

			if(lang) {
				fetchURL = `${fetchURL}&site=${lang}`
			}

            const res = await fetch(fetchURL);

            const data = await res.json();
            const entry = data.data[0];


            this.headline = entry.baseBlockTitle;

			const sectionHandles = globalSet.globalSectionHandleName[0];
            this.subline = sectionHandles[entry.sectionHandle];

            this.cta = entry.baseBlockCta;
            this.href = entry.uri;
			if(entry.baseBlockMedia[0].hasFocalPoint) {
				const fpx = entry.baseBlockMedia[0].focalPoint[0];
				const fpy = entry.baseBlockMedia[0].focalPoint[1];
				this.image = `${entry.baseBlockMedia[0].url}?w=1280h=696&fit=crop&crop=focalpoint&fp-x=${fpx}&fp-y=${fpy}&auto=format,compress`;
			} else {
				this.image = `${entry.baseBlockMedia[0].url}?w=1200&auto=format,compress`;
			}
			this.sectionHandle = entry.sectionHandle;
        },
		dataloaded(data, globalSet, lang) {
            const crossLink = data.crossLink ? data.crossLink[0] : null;
            if(!crossLink) {
                // no crosslink
                this.getRandomEntry(data.id, globalSet, lang);
                return;
            }

            const crossSubline = crossLink.baseCrossLinkSubline;
            const crossHeadline = crossLink.baseCrossLinkHeadline;
            const crossCta = crossLink.baseCrossLinkCtaCopy;
            const crossUrl = crossLink.crossLinkUrl;

            const entry = (crossLink.baseCrossLinkEntry && crossLink.baseCrossLinkEntry.length) ? crossLink.baseCrossLinkEntry[0] : null;

            if(entry) {
                // entry crosslink
                const uri = entry.uri;

				const sectionHandles = globalSet.globalSectionHandleName[0];
                const subline = crossSubline ? crossSubline : sectionHandles[entry.sectionHandle];
                const headline = crossHeadline ? crossHeadline : entry.baseBlockTitle;
                const cta = crossCta ? crossCta : entry.baseBlockCta;
				let image;
				if(entry.baseBlockMedia[0].hasFocalPoint) {
					const fpx = entry.baseBlockMedia[0].focalPoint[0];
					const fpy = entry.baseBlockMedia[0].focalPoint[1];
					image = `${entry.baseBlockMedia[0].url}?w=1280h=696&fit=crop&crop=focalpoint&fp-x=${fpx}&fp-y=${fpy}&auto=format,compress`;
				} else {
					image = `${entry.baseBlockMedia[0].url}?w=1200&auto=format,compress`;
				}

                this.subline = subline;
                this.headline = headline;
                this.cta = cta;
                this.href = uri;
                this.image = image;
				this.sectionHandle = entry.sectionHandle;
            } else {
                // href crosslink
                this.subline = crossSubline;
                this.headline = crossHeadline;
                this.cta = crossCta;
                this.href = crossUrl;
                this.buttonType = 'url';
            }
		},
    },
	computed: {
		modalContextColor() {
			const sectionHandle = this.sectionHandle;
			switch(sectionHandle) {
				case 'corporateProducts': return colors[sectionHandle][0];
				case 'corporateCompany': return colors[sectionHandle][0];
				case 'corporateSales': return colors[sectionHandle][0];
				default: return '#000000';
			}
		},
		modalContextColorDarker() {
			const sectionHandle = this.sectionHandle;
			switch(sectionHandle) {
				case 'corporateProducts': return colors[sectionHandle][1];
				case 'corporateCompany': return colors[sectionHandle][1];
				case 'corporateSales': return colors[sectionHandle][1];
				default: return '#000000';
			}
		},
		nextEntryHref() {
			return `/${this.href}`;
		},
	},
	created() {
		this.dataloadedHandle = this.dataloaded.bind(this);
	},
	mounted() {
        this.$root.$on('ModalLayout.dataloaded', this.dataloadedHandle);
	},
	beforeUpdate() {
        this.$root.$off('ModalLayout.dataloaded', this.dataloadedHandle);
	},
	updated() {
        this.$root.$on('ModalLayout.dataloaded', this.dataloadedHandle);
	},
	beforeDestroy() {
        this.$root.$off('ModalLayout.dataloaded', this.dataloadedHandle);
	},

}
</script>
<style lang="scss" scoped>
  @import '../assets/styles/variables.scss';

  .next-entry {
    background-color: $white;
    border-radius: $grid-item-border-radius;
    display: flex;
    flex-direction: column;
    margin: 56px 24px;
    min-height: 348px;
    overflow: hidden;

    @media (min-width: $br-md) {
      flex-direction: row;
      margin-left: 0;
      margin-right: 0;
      margin-top: 24px;
    }

    .next-entry-image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      flex-basis: 100%;
      min-height: 224px;

      @media (min-width: $br-md) {
        flex-basis: 50%;
        min-height: 0;
      }
    }

    .next-entry-copy {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 42px 24px;

      @media (min-width: $br-md) {
        flex-basis: 50%;
        padding: 0;
      }

      ::v-deep {
        .typo-title {
          color: var(--modalContextColor);
          margin-bottom: 16px;
        }

        .typo-heading {
          margin-bottom: 24px;
          text-align: center;
          width: 90%;
        }
      }
    }
  }
</style>

