export default `
... on pageBlocks_gridItemEntry_BlockType {
  gridItemType: __typename
  uid
  baseImageRounded
  baseBlockSize
  baseColorpicker
  baseHideImage
  baseImageGradient
  baseFontLight
  baseEntryLink {
    uri
    sectionHandle
    ... on corporateCompany_corporateCompany_Entry {
      baseBlockTitle
      baseBlockMedia {
        url
        kind
        hasFocalPoint
        focalPoint
      }
      baseBlockCta
    }
    ... on corporateSales_corporateSales_Entry {
      baseBlockTitle
      baseBlockMedia {
        url
        kind
        hasFocalPoint
        focalPoint
      }
      baseBlockCta
    }
    ... on corporateProducts_corporateProducts_Entry {
      baseBlockTitle
      baseBlockMedia {
        url
        kind
        hasFocalPoint
        focalPoint
      }
      baseBlockCta
    }
    ... on corporateCollection_corporateCollection_Entry {
      baseBlockTitle
      baseBlockMedia {
        url
        kind
        hasFocalPoint
        focalPoint
      }
      baseBlockCta
    }
    ... on corporateSustainability_corporateSustainability_Entry {
      baseBlockTitle
      baseBlockMedia {
        url
        kind
        hasFocalPoint
        focalPoint
      }
      baseBlockCta
    }
  }
}
`;
