export default `
... on pageBlocks_gridItemScratcherCard_BlockType {
  gridItemType: __typename
  uid
  baseBlockSize
  baseHeadline
  baseColorpicker
  baseImage {
    url
    kind
    hasFocalPoint
    focalPoint
  }
  baseCtaCopy
  baseHeadlineTwo
  baseCtaCopyTwo
  baseLink {
    url
    type
    element {
      uri
    }
    customText
    target
  }
}
`;
