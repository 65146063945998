<template>
    <div class="typo-title" :class="{'is-category': category}"><slot /></div>
</template>
<script>
export default {
    props: {
        category: {
            type: Boolean,
            required: false,
        }
    }
}
</script>
<style lang="scss" scoped>
  @import '../../assets/styles/variables.scss';

  .typo-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;

    @media (min-width: $br-md) {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }

    &.is-category {
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.5px;
      line-height: 150%;
      text-transform: uppercase;

      @media (min-width: $br-md) {
        font-size: 20px;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.5px;
        line-height: 30px;
        text-transform: uppercase;
      }
    }
  }
</style>
