const entryData = `
baseBlockTitle
baseBlockCta
baseBlockMedia {
  url
  kind
  hasFocalPoint
  focalPoint
}
`;

export default `
crossLink {
  ... on crossLink_crossLinkToEntry_BlockType {
    baseCrossLinkHeadline
    baseCrossLinkSubline
    baseCrossLinkCtaCopy
    baseCrossLinkEntry {
      uri
      baseBlockTitle
      baseBlockCta
      sectionHandle
      ... on corporateCollection_corporateCollection_Entry {
        ${entryData}
      }
      ... on corporateProducts_corporateProducts_Entry {
        ${entryData}
      }
      ... on corporateCompany_corporateCompany_Entry {
        ${entryData}
      }
      ... on corporateSales_corporateSales_Entry {
        ${entryData}
      }
    }
  }
  ... on crossLink_crossLinkToUrl_BlockType {
    baseCrossLinkHeadline
    baseCrossLinkSubline
    baseCrossLinkCtaCopy
    crossLinkUrl
  }
}
`;
