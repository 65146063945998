export default `
... on entryBlocks_awards_BlockType {
  id
  baseHeadline
  baseCopy
  baseAwards {
    ... on baseAwards_awarditem_BlockType {
      image {
        url
      }
      description
      linkUrl
    }
  }
}
`;
