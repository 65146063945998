<template>
<Container>
	<div class="collection-headline">
		<div class="grid-block grid grid-cols-4 md:grid-cols-12 col-gap-16 md:col-gap-24">
			<div class="col-span-4 md:col-span-8 md:col-start-3">
				<Headline :globalSet="globalSet" />
			</div>
		</div>
	</div>
</Container>
</template>
<script>

import Container from './Container';
import Heading from './typo/Heading';
import Tag from './Tag';

const Headline = {
	components: {
		Heading,
		Tag,
	},
	name: 'Headline',
	render: function(h) {
		// get component informations from the global set
		const componentsInfos = this.globalSet.collectionHeadlineColors.map(e => {
			return { [e.colorName]: e.color, uri: e.entrylink[0].uri };
		});

		// get the copy as a string
		let content = this.globalSet.collectionHeadlineWithColoredLinks;
		// strip the surrounding p tag
		content = content.replace(/<p>|<\/p>/g, '');
		// split string into parts
		content = content.split(/(<u>[^[</]*?<\/u>\[.*?\])/);
		// keep track of the component index
		let componentIndex = 0;

		// map over every part
		content = content.map(e => {
			if(e.indexOf('<') === 0) { // if iterator is a component
				const parts = e.match(/<u>([^[</]*?)<\/u>\[(.*?)\]/);
				// extract the color and get the corresponding hex code
				const color = componentsInfos[componentIndex][parts[2]];
				// extract the corresponding uri
				const uri = componentsInfos[componentIndex].uri;

				// increase index to keep track of the components
				componentIndex++;

				// return an object with the infos of the component
				return { type: 'component', content: parts[1], color: color, uri: uri };
			} else { // else it's a normal string
				// replace leading and tailing space with non-breaking space, to keep the margin between the elements
				// replace leading space with non-breaking space
				if(/^\s/.test(e)) {
					e = e.substring(1);
					e = '&nbsp;' + e;
				}
				// replace tailing space with non-breaking space
				if (/\s$/.test(e)) {
					e = e.slice(0, -1);
					e = e + '&nbsp;';
				}

				// return an object with the infos of the span element
				return { type: 'span', content: e };
			}
		});

		// map over every before created object and create a vue component with it
		content = content.map(e => {
			if(e.type === 'span') {
				// using vue's render function ('createElement' - here aliased with 'h') to create
				// components programmatically
				return h(Heading, {
					props: {
						tagLevel: 3,
						styleLevel: 2,
						noTagLevel: true,
					},
					class: ['collection-headline-part'],
					domProps: {
						innerHTML: e.content,
					},
				});
			} else {
				return h('router-link', {
					props: {
						to: `/${e.uri}`
					},
				}, [h(Tag, {
					props: {
						color: e.color,
						inverted: true,
					},
				}, e.content)]);
			}
		});

		// return the finished vue component with the child vue components
		return h('p', {
			class: [''],
		}, content);
	},
	props: {
		globalSet: {
			type: Object,
			required: true,
		},
	},
};

export default {
	components: {
		Container,
		Headline,
	},
	props: {
		globalSet: {
			type: Object,
			required: true,
		}
	},
	computed: {
		headlineParts() {
			return this.globalSet.collectionHeadlineWithLinks;
		},
	},
	methods: {
		getEntryLink(part) {
			return `/${part.baseEntryLink[0].uri}`;
		},
		getCta(part) {
			return part.baseCtaCopy || part.baseEntryLink[0].title;
		},
	},
}
</script>
<style lang="scss" scoped>

@import '../assets/styles/variables.scss';

.collection-headline {
	margin: 56px 0;
	text-align: center;

	@media (min-width: $br-md) {
		margin: 120px 0;
	}

	::v-deep {
		.collection-headline-part {
			display: inline-block;
		}
	}
}

</style>
