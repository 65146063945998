<template>
<div>
  <LoadingSpinner v-if="loading" />
  <div v-else>
    <template class="entry-blocks" v-for="(entryBlock, index) in data.entryBlocks">
      <EntryBlock v-if="entryBlocks[entryBlock.blockType]" :position="index" :data="entryBlock" :globalSet="globalSet" :componentName="entryBlocks[entryBlock.blockType]" :key="entryBlocks[entryBlock.blockType].uid" />
      <div v-else :key="index">
          Entry Block:
          <b>{{ entryBlock.blockType }}</b>
          is not yet created :(
      </div>
    </template>
    <template class="page-blocks" v-for="(pageBlock, index) in data.pageBlocks">
      <PageBlock v-if="pageBlocks[pageBlock.blockType]" :data="pageBlock" :globalSet="globalSet" :componentName="pageBlocks[pageBlock.blockType]" :key="pageBlocks[pageBlock.blockType].uid" />
      <div v-else :key="index">
          Block:
          <b>{{ pageBlock.blockType }}</b>
          is not yet created :(
      </div>
    </template>
  </div>
  <CollectionHeadline v-if="showCollectionHeadline && !loading" :globalSet="globalSet" />
</div>
</template>
<script>

import { fetchData } from '../cms/api';
import { PAGE_DATA_QUERY } from '../cms/queries';
import { pagesBlocksMap } from '../pageBlocksMap';
import { entryBlocksMap } from '../entryBlocksMap';
import PageBlock from '../components/PageBlock.vue';
import EntryBlock from '../components/EntryBlock.vue';
import CollectionHeadline from '../components/CollectionHeadline';
import LoadingSpinner from '../components/LoadingSpinner.vue';

export default {
  name: 'AbstractLayout',
  components: {
    PageBlock,
    EntryBlock,
    CollectionHeadline,
    LoadingSpinner,
  },
  data() {
    return {
      loading: false,
      data: null,
      error: null,
      pageBlocks: null,
      entryBlocks: null,
      gridDebug: false,
      loadedUri: null,
      globalSet: {},
      seoTitle: '',
      seoDescription: '',
      seoImage: '',
      seoNoIndexNoFollow: [],
    }
  },
  created() {
    this.pageBlocks = pagesBlocksMap;
    this.entryBlocks = entryBlocksMap;
    this.getData();
  },
  computed: {
    mySlugToWatch: function () {
      return '';
    },
    uri: function () {
      return '';
    },
    showCollectionHeadline() {
      return this.$route.meta.hasModal === false && this.$route.name === 'PageModalLayout';
    },
  },
  watch: {
    mySlugToWatch: 'getData',
  },
  methods: {
    async getData() {
      let uri = this.uri;

      if (uri === this.loadedUri && this.loadedUri !== null) return;

      this.error = this.data = null;
      this.loading = true;
      const lang = this.$route.query.lang || '';

      this.loadedUri = uri;

      if (lang) {
        uri = `${uri}?lang=${lang}`;
      }

      try {
        const data = await fetchData(PAGE_DATA_QUERY, {
          uri,
          site: lang ? lang : null,
        });

        if(data.entry === null) {
          this.$store.dispatch('updateErrorState', true);
          return;
        }


        this.loading = false;
        this.data = data.entry;
        this.globalSet = data.globalSet;

        const seoImageUrl = data.entry.metaOgImage.length ? data.entry.metaOgImage[0].url : data.globalSet.metaOgImage[0].url;
        this.seoImage = `${seoImageUrl}?w=1200&auto=format,compress`;

        this.seoTitle = data.entry.metaTitle ? data.entry.metaTitle : data.entry.title ? data.entry.title : data.globalSet.metaTitle;
        this.seoDescription = data.entry.metaDescription ? data.entry.metaDescription : data.globalSet.metaDescription;
        this.seoNoIndexNoFollow = data.entry.metaNoIndexNoFollow ? data.entry.metaNoIndexNoFollow : [];

        if((this.$options.name === 'Layout' && !this.$route.meta.hasModal)
          || (this.$options.name === 'ModalLayout' && this.$route.meta.hasModal)) {
          this.$store.dispatch('updateSeoMeta', {
            title: this.seoTitle,
            description: this.seoDescription,
            image: this.seoImage,
            noIndexNoFollow: this.seoNoIndexNoFollow,
          });
        }

        this.$root.$emit(`${this.$options.name}.dataloaded`, this.data, this.globalSet, lang);
      } catch (e) {
        this.error = true;
      }
    },
  }

}
</script>
<style lang="scss" scoped>
.grid-debug {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
</style>
