<template>
<div class="error-page" style="--modalContextColor: #000; --modalContextColorDarker: #000;">
	<Container>
		<Heading class="error-page-headline" :tag-level="1" :style-level="1">So sorry</Heading>
		<Heading class="error-page-subline" :tag-level="3" no-tag-level :style-level="3">Sorry, the page you are looking for is no longer available.</Heading>
		<Button icon="link" arrow-left icon-position="left" :data="{ url: '/corporate' }">Go Back</Button>
	</Container>
</div>
</template>
<script>
import Heading from '../components/typo/Heading';
import Container from '../components/Container';
import Button from '../components/Button';

export default {
	components: {
		Heading,
		Container,
		Button,
	},
}
</script>
<style lang="scss" scoped>
@import '../assets/styles/variables.scss';

.error-page {
	text-align: center;
	padding: 64px 0;
	@media (min-width: $br-md) {
		padding: 80px 0 144px;
	}

	.error-page-headline {
		margin-bottom: 16px;

		@media (min-width: $br-md) {
			margin-bottom: 24px;
		}
	}

	.error-page-subline {
		color: $gray-dark;
		max-width: 600px;
		margin: 0 auto;
		margin-bottom: 32px;

		@media (min-width: $br-md) {
			margin-bottom: 48px;
		}
	}
}
</style>
