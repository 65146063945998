export default `
... on entryBlocks_formattedList_BlockType {
  baseHideNumbers
  baseFormattedList {
    ... on baseFormattedList_formattedListItem_BlockType {
      headline
      listItem
    }
  }
}
`;
