export const entryBlocksMap = {
  'entryBlocks_media_BlockType': 'Media',
  'entryBlocks_mediaGrid_BlockType': 'MediaGrid',
  'entryBlocks_twoMediaWithOptionalCaption_BlockType': 'TwoMedia',
  'entryBlocks_quote_BlockType': 'Quote',
  'entryBlocks_imageSlider_BlockType': 'ImageSlider',
  'entryBlocks_formattedList_BlockType': 'FormattedList',
  'entryBlocks_copy_BlockType': 'Copy',
  'entryBlocks_checkList_BlockType': 'CheckList',
  'entryBlocks_headlineCopy_BlockType': 'HeadlineCopy',
  'entryBlocks_button_BlockType': 'Button',
  'entryBlocks_timeline_BlockType': 'Timeline',
  'entryBlocks_companyLogos_BlockType': 'Awards',
  'entryBlocks_companyLogosWithDescription_BlockType': 'AwardsWithCopy',
  'entryBlocks_awards_BlockType': 'AwardsWithHeadlineAndCopy',
}
