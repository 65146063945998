export default `
... on entryBlocks_quote_BlockType {
  baseQuote
  baseQuoteImage {
    url
  }
  baseQuoteFrom
  baseQuoteFromSubtitle
}
`;
