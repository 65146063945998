<template>
<div class="browser-support-page" style="--modalContextColor: #000; --modalContextColorDarker: #000;">
	<Container>
		<Heading class="browser-support-page-headline" :tag-level="1" :style-level="1">Your browser is not being supported</Heading>
		<Body big class="browser-support-page-subline">In order to use the website you will need to update your browser. We recommend the latest version of <u>Google Chrome</u>, <u>Mozilla Firefox</u>, <u>Safari</u> or <u>Edge</u>.</Body>
	</Container>
</div>
</template>
<script>
import Heading from '../components/typo/Heading';
import Body from '../components/typo/Body';

export default {
	components: {
		Heading,
		Body,
	},
}
</script>
<style lang="scss" scoped>
@import '../assets/styles/variables.scss';

.browser-support-page {
	text-align: center;
	padding: 64px 0;
	@media (min-width: $br-md) {
		padding: 160px 0 144px;
	}

	.browser-support-page-headline {
		margin-bottom: 16px;

		@media (min-width: $br-md) {
			margin-bottom: 24px;
		}
	}

	.browser-support-page-subline {
		color: $gray-dark;
		max-width: 600px;
		margin: 0 auto;
		margin-bottom: 32px;

		@media (min-width: $br-md) {
			margin-bottom: 48px;
		}
	}
}
</style>
