export default `
... on entryBlocks_companyLogosWithDescription_BlockType {
    baseCompanyLogosWithDescription {
    ... on baseCompanyLogosWithDescription_companyLogoItem_BlockType {
      uid
      logo {
        url
        hasFocalPoint
        focalPoint
        kind
      }
      headline
      copy
      linkUrl
    }
  }
}
`;
