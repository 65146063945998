export default `
... on entryBlocks_twoMediaWithOptionalCaption_BlockType {
  children {
        ... on entryBlocks_mediaWithCaption_BlockType {
      baseCaption
      baseImage {
        url
        kind
      }
      baseVideo {
        url
        kind
      }
    }
  }
}
`;
