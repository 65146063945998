export default `
... on entryBlocks_button_BlockType {
    baseAlignment
  baseIcon
  baseIconPosition
  baseButton {
    ariaLabel
    customText
    target
    text
    title
    type
    url
    element {
      uri
    }
  }
}
`;
