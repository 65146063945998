<script>
export default {
    props: {
        tagLevel: {
            type: Number,
            required: true,
        },
        styleLevel: {
            type: Number,
            required: true,
        },
		noTagLevel: {
			type: Boolean,
			required: false,
			default: false,
		},
    },
    render(h) {
        return h(
        this.noTagLevel ? 'div' : 'h' + this.tagLevel,
        {
            'class': ['typo-heading', 'h' + this.styleLevel]
        },
        this.$slots.default
        );
    }
}
</script>
<style lang="scss" scoped>

@import '../../assets/styles/variables.scss';
@import '../../assets/styles/typo.scss';

.h1 {
	@include heading-h1;
}

.h2 {
	@include heading-h2;
}

.h3 {
	@include heading-h3;
}
</style>

