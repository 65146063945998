<template>
<div class="container" :class="{'is-small': small}">
    <slot></slot>
</div>
</template>
<script>
export default {
    props: {
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
}
</script>
<style lang="scss" scroped>
@import '../assets/styles/variables.scss';

.container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 24px;

    &.is-small {}

    @media (min-width: $br-md) {
        padding: 0 32px;
    }

}
</style>
