<template>
  <div class="modal-wrapper" ref="modalwrapper" :class="[{'loaded': loaded, 'fade-in': fadeIn}]" :style="{ '--buttonTextColor': buttonTextColor,'--backdropColor': backdropColor, '--modalContextColor': modalContextColor, '--modalContextColorDarker': modalContextColorDarker}">
    <div class="modal" v-click-outside="onClickOutside">
      <div class="loading-spinner-wrapper" v-if="!loaded">
        <LoadingSpinner />
      </div>
      <router-link v-if="loaded" :to="{ path: returnUri, props: { closeModal: true } }" class="modal-close" />
      <div class="modal-inner" v-show="loaded">
        <slot />
        <div class="modal-socials">
          <ShareButton has-tooltip></ShareButton>
          <ShareButton facebook></ShareButton>
          <ShareButton pinterest></ShareButton>
        </div>
      </div>
      <div class="modal-next-entry" :class="{'is-loaded': loaded}">
        <NextEntry />
      </div>
    </div>
  </div>
</template>
<script>

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import colors from '../entryBackgroundColor';
import ShareButton from '../components/ShareButton';
import NextEntry from '../components/NextEntry';
import vClickOutside from 'v-click-outside';
import LoadingSpinner from '../components/LoadingSpinner';

export default {
  components: {
    ShareButton,
    NextEntry,
    LoadingSpinner,
  },
  data() {
    return {
      sectionHandle: 'default',
      escHandle: null,
      fadeIn: false,
      dataloadedHandle: null,
    };
  },
  computed: {
    returnUri () {
      return `/${this.$parent.$refs.mainLayout.loadedUri}`;
    },
    backdropColor () {
      const sectionHandle = this.sectionHandle;
      switch(sectionHandle) {
        case 'corporateProducts': return colors[sectionHandle][0] + 'C8';
        case 'corporateCompany': return colors[sectionHandle][0] + 'C8';
        case 'corporateSales': return colors[sectionHandle][0] + 'C8';
        default: return 'transparent';
      }
    },
    modalContextColor () {
      const sectionHandle = this.sectionHandle;
      switch(sectionHandle) {
        case 'corporateProducts': return colors[sectionHandle][0];
        case 'corporateCompany': return colors[sectionHandle][0];
        case 'corporateSales': return colors[sectionHandle][0];
        default: return '#000000';
      }
    },
    modalContextColorDarker () {
      const sectionHandle = this.sectionHandle;
      switch(sectionHandle) {
        case 'corporateProducts': return colors[sectionHandle][1];
        case 'corporateCompany': return colors[sectionHandle][1];
        case 'corporateSales': return colors[sectionHandle][1];
        default: return '#000000';
      }
    },
    buttonTextColor () {
      if(this.sectionHandle === 'corporateSales') {
        return '#000000';
      } else {
        return '#ffffff';
      }
    },
    loaded () {
      return this.sectionHandle !== 'default';
    },
  },
  created() {
    this.dataloadedHandle = this.onDataLoaded.bind(this);
    this.$root.$on('ModalLayout.dataloaded', this.dataloadedHandle);
  },
  methods: {
    onDataLoaded(data) {
      this.sectionHandle = data.sectionHandle;
    },
    onClickOutside() {
      this.$router.push({
        path: this.returnUri,
        query: {
          lang: this.$route.query.lang,
        }
      });
    },
    handleEsc(e) {
      if(e.keyCode === 27) {
        this.onClickOutside();
      }
    },
  },
  mounted () {
      this.fadeIn = true;
      disableBodyScroll(this.$el);

      this.escHandle = this.handleEsc.bind(this);
      window.addEventListener('keyup', this.escHandle);
  },
  beforeUpdate() {
      enableBodyScroll(this.$el);
      this.$root.$off('ModalLayout.dataloaded', this.dataloadedHandle);
  },
  updated () {
      disableBodyScroll(this.$el);
      this.$root.$on('ModalLayout.dataloaded', this.dataloadedHandle);
  },
  beforeDestroy () {
      enableBodyScroll(this.$el);
      window.removeEventListener('keyup', this.escHandle);
      this.$root.$emit('modalclosed');
      this.$root.$off('ModalLayout.dataloaded', this.dataloadedHandle);
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
}
</script>
<style lang="scss" scoped>
  @import '../assets/styles/variables.scss';

  $br-smaller-modal: 1258px; // 1280 - 20(scrollbar width) - 2(buffer)
  $br-bigger-modal: 1480px;

  .modal-wrapper {
    bottom: 0;
    left: 0;
    opacity: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 0.8s ease;
    width: 100%;
    z-index: 9999;

    &.fade-in {
      opacity: 1;
    }

    &.loaded {
      backdrop-filter: blur(3px);
      /*transition: background-color 0.3 ease;*/
      background-color: var(--backdropColor);
    }

    .modal {
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;

      @media (min-width: $br-smaller-modal) {
        margin: 56px auto;
        max-width: 1088px;
      }

      @media (min-width: $br-bigger-modal) {
        max-width: 1280px;
      }

      .loading-spinner-wrapper {
        align-items: center;
        backdrop-filter: blur(3px);
        background-color: rgba(255, 255, 255, 0.3);
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
      }

      .modal-inner {
        background-color: $white;
        border-radius: $grid-item-border-radius;
        box-shadow: 0 40px 80px -10px rgba(0, 0, 0, 0.1), 0px 100px 160px -30px rgba(0, 0, 0, 0.2);
        overflow: auto;
        position: relative;
        top: -48px;

        @media (min-width: $br-smaller-modal) {
          position: static;
          top: auto;
        }

        @media (max-width: $br-xl) {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        ::v-deep {
          .loading-spinner {
            margin-top: 128px;

            @media (min-width: $br-md) {
              margin-top: 200px;
            }
          }
        }

        .modal-socials {
          display: flex;
          justify-content: center;
          margin: 48px 0;

          @media (min-width: $br-md) {
            margin: 128px 0;
          }

          ::v-deep {
            .share-button {
              margin-right: 40px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        img.media-item {
          border-radius: 0;
        }
      }

      .modal-next-entry {
        opacity: 0;
        position: relative;

        @media (min-width: $br-md) {
          box-shadow: 0 40px 80px -10px rgba(0, 0, 0, 0.1), 0px 100px 160px -30px rgba(0, 0, 0, 0.2);
        }

        &.is-loaded {
          border-radius: $grid-item-border-radius;
          opacity: 1;
        }
      }

      .modal-close {
        background-color: var(--modalContextColor);
        border-radius: 50%;
        display: block;
        height: 48px;
        left: calc(50% - 24px);
        outline: none;
        position: sticky;
        top:  40px;
        transition: all 0.3s ease;
        width: 48px;
        z-index: 100;

        @media (min-width: $br-smaller-modal) {
          background-color: $white;
          height: 56px;
          left: auto;
          position: absolute;
          right: -72px;
          top:  0;
          width: 56px;
        }

        @media (min-width: $br-bigger-modal) {
          right: -80px;
        }

        &:after {
          background-image: url('../assets/icons/close-white.svg');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          display: block;
          left: 50%;
          height: 20px;
          outline: none;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 20px;
          @media (min-width: $br-smaller-modal) {
            background-image: url('../assets/icons/close.svg');
          }
        }

        &:hover {
          transform: scale(0.95) rotate(90deg);
        }
      }
    }
  }
</style>

<style lang="scss">
  @import '../assets/styles/variables.scss';
  .modal {
    .container {
      padding: 0 24px;
    }
  }
</style>
