export default `
... on entryBlocks_media_BlockType {
  baseWidth
  baseImage {
    url
    kind
  }
  baseVideo {
    url
    kind
  }
  baseCaption
  baseExternalVideo
  baseExternalVideoType
  externalVideoId
}
`;
