export default `
... on pageBlocks_gridItemMedia_BlockType {
  gridItemType: __typename
  uid
  baseImage {
    url
    kind
    hasFocalPoint
    focalPoint
  }
  baseVideo {
    url
    kind
    mimeType
  }
  baseBlockSize
  baseShowPlayButton
  baseImageGradient
  baseEntryLink {
    uri
    sectionHandle
    ... on corporateCompany_corporateCompany_Entry {
      baseBlockTitle
      baseBlockMedia {
        url
        kind
      }
      baseBlockCta
    }
    ... on corporateSales_corporateSales_Entry {
      baseBlockTitle
      baseBlockMedia {
        url
        kind
      }
      baseBlockCta
    }
    ... on corporateProducts_corporateProducts_Entry {
      baseBlockTitle
      baseBlockMedia {
        url
        kind
      }
      baseBlockCta
    }
    ... on corporateCollection_corporateCollection_Entry {
      baseBlockTitle
      baseBlockMedia {
        url
        kind
      }
      baseBlockCta
    }
  }
}
`;
