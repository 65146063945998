export default `
... on pageBlocks_gridItemCopy_BlockType {
  gridItemType: __typename
  uid
  baseBlockSize
  baseColorpicker
  baseImage {
    url
    kind
  }
  baseHeadline
  baseCopy
}
`
