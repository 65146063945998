import { render, staticRenderFns } from "./Body.vue?vue&type=template&id=d4b5599a&scoped=true"
import script from "./Body.vue?vue&type=script&lang=js"
export * from "./Body.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./Body.vue?vue&type=style&index=0&id=d4b5599a&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../tmp/.npm/_npx/172/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "d4b5599a",
  null
  ,true
)

export default component.exports