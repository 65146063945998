export default `
... on entryBlocks_timeline_BlockType {
  baseTimeline {
    ... on baseTimeline_item_BlockType {
      uid
      year
      headline
      copy
      media {
        url
        hasFocalPoint
        focalPoint
        kind
      }
    }
  }
}
`;

