<template>
  <div class="loading-spinner"></div>
</template>
<script>
export default {}
</script>
<style>
.loading-spinner {
	width: 64px;
	height: 64px;
	border: 2px solid transparent;
	border-color: #eee;
	border-top-color: black;
	border-radius: 50%;
	animation: loadingspin 1s ease infinite;
    margin: 34px auto;
}

@keyframes loadingspin {
	100% {
			transform: rotate(360deg)
	}
}
</style>
