import { request } from 'graphql-request'
import { print } from 'graphql/language/printer'

const api = `https://corporate.api.ringana.com/api`;

export const fetchData = async (
    query,
    vars
) => {
    const queryString = typeof query === 'string' ? query : print(query)

    try {
        const data = await request(api, queryString, vars)
        return data
    } catch (e) {
        //console.error(e)
        return {}
    }
}

export const fetchURL = async(url, vars) => {
    try {
        let u = url;
        if(vars) {
            u += '?';
            for(let prop in vars) {
                u += `${prop}=${vars[prop]}`;
                u += '&';
            }
            u = u.substring(0, u.length - 1);
        }

        const data = (await fetch(u)).json();
        return data;
    } catch (e) {
        //console.error(e);
        return {};
    }
};

