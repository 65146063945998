import { render, staticRenderFns } from "./CollectionHeadline.vue?vue&type=template&id=6ac759ee&scoped=true"
import script from "./CollectionHeadline.vue?vue&type=script&lang=js"
export * from "./CollectionHeadline.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./CollectionHeadline.vue?vue&type=style&index=0&id=6ac759ee&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../tmp/.npm/_npx/172/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "6ac759ee",
  null
  ,true
)

export default component.exports