import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'
import ModalLayout from '../views/ModalLayout.vue'

Vue.use(VueRouter)

import store from '../store/index';

const routes = [
  {
    path: '/corporate',
    name: 'FrontpageLayout',
    component: Layout,
    meta: {
      hasModal: false,
      smallMarginTop: true,
    }
  },
  {
    path: '/corporate/:pageSlug',
    name: 'PageLayout',
    component: Layout,
    meta: {
      hasModal: false,
      bigMarginTop: true,
    }
  },
  {
    path: '/corporate/:pageSlug/:entrySlug',
    name: 'PageModalLayout',
    components: {
      default: Layout,
      modal: ModalLayout
    },
    meta: {
      hasModal: true,
      bigMarginTop: true,
    },
  },
]

function setModalRouteState(to) {
    if(!to.params.pageSlug || to.params.pageSlug && !to.params.entrySlug)
        return;

    const hasModal = store.state.collectionHandles.filter(handle => {
        return to.params.pageSlug === handle
    }).length ? false : true;

    to.meta.hasModal = hasModal;
}

const router = new VueRouter({
  routes,
  mode: 'history',
})

router.beforeEach((to, _from, next) => {
    // adding lang query param so we don't have to add in every $router.push and router-link :to
    let newRoute = null;
      if (_from && _from.query && _from.query.lang && (!to.query || (to.query && !to.query.lang))) {
          newRoute = {
          name: to.name,
          params: to.params,
          path: to.path,
          query: {
              ...to.query,
              lang: _from.query.lang,
          },
          meta: to.meta,
          replace: true,
      };
    }
    if(!store.state.collectionHandlesLoaded) {
      store.dispatch('loadCollectionHandles').then(() => {
        setModalRouteState(to);
        if (newRoute !== null) next(newRoute); else next();
      }).catch(() => {
        //console.log(e)
        if (newRoute !== null) next(newRoute); else next();
      });
    } else {
      setModalRouteState(to);
      if (newRoute !== null) next(newRoute); else next();
    }
})

router.afterEach((_to, from ) => {
    // Don't trigger google analytics on initial page load. This get's handled by the main page.
    // The from.path variable is set to '/' only on the intial page load.
    // So we check, if the load is inital or not.
    // If not, we trigger ga, so we only track the client side navigation.
    if(typeof window.ga === 'function' && from.path !== '/') {
        window.ga('set', 'page', window.location.href);
        window.ga('send', 'pageview');
    }
})

export default router
