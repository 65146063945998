export default `
... on entryBlocks_companyLogos_BlockType {
  baseCompanyLogos {
    ... on baseCompanyLogos_companyLogoItem_BlockType {
      uid
      logo {
        url
        hasFocalPoint
        focalPoint
        kind
      }
      linkUrl
    }
  }
}
`;

