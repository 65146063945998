export default `
... on pageBlocks_collectionsTeaser_BlockType {
  baseImage {
    url
    kind
  }
  baseCollectionShowAllText
  basePageLink {
    element {
      uri
    }
  }
  listItems: children {
    ... on pageBlocks_collectionLink_BlockType {
      baseCollectionlink {
        customText
        element {
          uri
          ... on corporateCollection_corporateCollection_Entry {
            baseBlockTitle
          }
        }
      }
      basePlainText
    }
  }
}
`;
