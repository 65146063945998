<script>
export default {
	props: {
		facebook: {
			type: Boolean,
			required: false,
		},
		pinterest: {
			type: Boolean,
			required: false,
		},
		hasTooltip: {
			type: Boolean,
			required: false,
		},
	},
	computed: {
		type () {
			if(this.facebook) return 'is-facebook';
			else if(this.pinterest) return 'is-pinterest';
			else return null;
		},
		link () {
			const url = window.location.href;
			if(this.facebook) return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&display=popup&ref=plugin&src=share_button`;
			else if(this.pinterest) return `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}`;
			else return null;
		}
	},
	methods: {
		handleClick() {
			if(this.type !== null) return;
            const dummy = document.createElement('input'),
            text = window.location.href;

			document.body.appendChild(dummy);
			dummy.value = text;
			dummy.select();
			dummy.setSelectionRange(0, 99999);
			document.execCommand('copy');
			document.body.removeChild(dummy);
		},
	},
    render(h) {
        return h(
			this.type === null ? 'div' : 'a',
			{
				class: [
                  'share-button',
                  this.type,
                  { 'has-tooltip': this.hasTooltip },
				],
				attrs: {
					href: this.link ? this.link : null,
					target: this.type !== null ? '_blank' : null,
					rel: this.type !== null ? 'noopener noreferrer' : null,
				},
				on: {
					click: this.handleClick,
				},
			},
        );
    }
}
</script>
<style lang="scss" scoped>

@import '../assets/styles/variables.scss';

.share-button {
	display: block;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background-color: var(--modalContextColor);
	position: relative;
	transition: all 0.3s ease;
	cursor: pointer;

	@media (min-width: $br-md) {
		width: 56px;
		height: 56px;
	}


	&:after {
		content: '';
		position: absolute;
		display: block;
		width: 20px;
		height: 20px;
		top: 14px;
		left: 14px;
		background-image: url('../assets/icons/link.svg');
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;

		@media (min-width: $br-md) {
			top: 18px;
			left: 18px;
		}
	}

	&:hover {
		background-color: var(--modalContextColorDarker);
	}

	&.is-facebook {
		&:after {
			background-image: url('../assets/icons/facebook.svg');
		}
	}

	&.is-pinterest {
		&:after {
			background-image: url('../assets/icons/pinterest.svg');
		}
	}

	&.has-tooltip {
		&:before {
			content: 'copy to clipboard';
			position: absolute;
			bottom: calc(100% + 6px);
			left: 50%;
			transform: translateX(-50%);
			background-color: $black;
			color: $white;
			opacity: 0;
			padding: 6px 14px;
			border-radius: 15px;
			white-space: nowrap;
			transition: all 0.3s ease;
		}

		&:hover {
			&:before {
				opacity: 1;
				bottom: calc(100% + 8px);
			}
		}
	}
}
</style>
