<template>
    <component v-bind:is="componentFile" :data="data" :position="position" :layoutName="layoutName" />
</template>
<script>
export default {
    props: {
        componentName: {
            type: String,
            required: true
        },
        data: {
            type: Object,
            required: true
        },
        globalSet: {
            type: Object,
            required: true
        },
        position: {
            type: Number,
            required: true,
        },
    },
    computed: {
        componentFile () {
            return () => import(`./entryBlocks/${this.componentName}`)
        },
        layoutName () {
            return this.$parent.$options.name;
        },
    }
}
</script>
