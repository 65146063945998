import { render, staticRenderFns } from "./Title.vue?vue&type=template&id=10809a54&scoped=true"
import script from "./Title.vue?vue&type=script&lang=js"
export * from "./Title.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./Title.vue?vue&type=style&index=0&id=10809a54&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../tmp/.npm/_npx/172/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "10809a54",
  null
  ,true
)

export default component.exports