<template>
    <div class="typo-body" :class="{'is-big': big, 'is-strong': strong}"><slot /></div>
</template>
<script>
export default {
    props: {
        big: {
            type: Boolean,
            required: false,
        },
        strong: {
            type: Boolean,
            required: false,
        }
    }
}
</script>
<style lang="scss" scoped>

@import '../../assets/styles/variables.scss';
@import '../../assets/styles/typo.scss';

    .typo-body {
        @include body;

        &.is-big {
            @include body-big;
        }

        &.is-strong {
            @include body-strong;
        }

    }
</style>
