import gridItemCopyFragment from '../gridItems/Copy';
import gridItemEntryFragment from '../gridItems/Entry';
import gridItemRandomLetterFragment from '../gridItems/RandomLetter';
import gridItemScratcherCardFragment from '../gridItems/ScratcherCard';
import gridItemLinkFragment from '../gridItems/Link';
import gridItemMediaFragment from '../gridItems/Media';

export default `
... on pageBlocks_grid_BlockType {
  uid
  gridItems: children {
    id
    ${gridItemEntryFragment}
    ${gridItemRandomLetterFragment}
    ${gridItemCopyFragment}
    ${gridItemScratcherCardFragment}
    ${gridItemLinkFragment}
    ${gridItemMediaFragment}
  }
}
`;
